.insight-container--vertical {
  box-shadow: -5px 5px 5px $shadow-color;
  z-index: 100;
}

.insights--title {
  text-align: center;
  h2 {
    color: #454545;
    font-size: 24px;
    font-weight: bold;
    padding-top: 50px;
  }
  p {
    font-size: 14px;
    color: #454545;
    padding: 0 20px;
  }
}

.insights--label {
  background: #0c3888;
  border-radius: 10px;
  color: #ffffff;
  line-height: 15px;
  font-size: 10px;
  padding: 15px 20px;
  margin: 15px 10px;
  text-align: left;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.insights-text-decoration {
  text-decoration: underline;
  color: white;
  &:hover {
    color: white;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
