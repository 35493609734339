.event-panel {
  flex-shrink: 0;
}

.event-panel-disabled {
  pointer-events: none;
  opacity: 0.4;
  background: #fafbfc !important;
  padding-top: 0.5rem !important;
}

.panel-sticky {
  position: sticky;
  top: 0;
  z-index: 71;
}

.event-popup {
  transition: all 0.3s ease-out;
}

.event-filter-types {
  /* Dropshadow 2 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  &--icons {
    box-sizing: border-box;
    transform: rotate(-15deg);
    padding: 2px;
    height: 36px;
    width: 36px;
  }
}

.event-filter__stations-switch {
  float: right;
  vertical-align: middle;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 6px;
}

.user-filter-tags {
  border-radius: 10px;
  padding: 1px 5px 1px 15px;
}

.event-date-filter {
  width: 50%;
}

.event-name-filter {
  width: 55% !important;
  button {
    border-radius: 6px;
  }
}

.search-icon-bg {
  border-radius: 0 6px 6px 0 !important;
}
.navigate-icon-bg {
  /* Dropshadow 2 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  height: 36px;
  width: 36px;
  padding: 5px;
  &:disabled {
    background: white !important;
    svg {
      path {
        fill: $color--gray4;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: $color--white;
    }
  }
}

::placeholder {
  color: $color--gray5 !important;
}

%active-filter {
  .sport-filters__icon path {
    fill: $color--gray5 !important;
  }
  .sport-filters__label {
    display: initial !important;
  }
}

.sport-filters {
  &__item {
    cursor: pointer;
    &:hover {
      @extend %active-filter;
    }
    .sport-filters__icon path {
      fill: black;
    }
    .sport-filters__label {
      display: none;
    }
  }
  &--selected {
    @extend %active-filter;
    .sport-filters__underline {
      visibility: visible;
    }
  }
  &__icon {
    width: 40px;
  }
  &__label {
    margin-top: 2px;
    text-transform: capitalize;
    color: $color--navy !important;
    top: 50%;
    transform: translateY(-50%);
    line-height: 14px !important;
  }
  &__underline {
    margin: 0 auto;
    margin-top: 2px;
    width: 53px;
    height: 3px;
    bottom: 0;
    background: linear-gradient(
      183.24deg,
      $color--navy2 3.26%,
      $color--navy 100%
    );
    border-radius: 6px 6px 0px 0px;
    visibility: hidden;
  }
  &__reset {
    line-height: 145%;
    u {
      color: $color--gray10 !important;
      &:hover {
        color: $color--gray7 !important;
      }
    }
  }
}
