.checkbox-wrapper {
  display: block;
  height: 22px;
  width: 22px;
  position: relative;
  padding-left: 35px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    .checkmark {
      border: 2px solid $color--default-blue;
    }
    input:checked + .checkmark {
      border: none;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    z-index: 3;
    @extend .element--clickable;

    &:checked + .checkmark {
      background: $color--default-blue;
      border: none;
      border-radius: 6px;

      &:after {
        display: block;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background: #ffffff;
    border: 2px solid $color--gray4;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    z-index: 2;

    &:after {
      content: "";
      display: none;
      position: absolute;
      left: 8px;
      top: 5px;
      width: 6px;
      height: 10px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &--danger {
      border-color: $color--red1;  
    }
  }

  .checkmark--white-background {
    border: none;
  }
}
