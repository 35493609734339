// Fonts
$font-family--primary: "Asap", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-table-header-small: 11px;

//---------------- Breakpoints----------------------
$brkpoint-xl: 1800px;

$shadow-color: rgba(0, 0, 0, 0.1);

//--------------------Colors------------------------
$color--white: #ffffff;
$color--gray1: #fafbfc;
$color--gray2: #f7f8fa;
$color--gray3: #f0f1f5;
$color--gray4: #e6e8ed;
$color--gray5: #d5d8e0;
$color--gray6: #bcc0cc;
$color--gray7: #959bab;
$color--gray8: #5d6375;
$color--gray9: #14171f;
$color--gray10: #454545;
$color--gray11: #808080;
$color--yellow1: #ffcf5c;
$color--yellow2: #ffe29d;
$color--yellow3: #fff8e7;
$color--orange1: #ffa26b;
$color--orange2: #ffc7a6;
$color--orange3: #ffe8da;
$color--default-blue: #34b3f5;
$color--blue1: #0084f4;
$color--blue2: #66b5f8;
$color--blue3: #d5e9fa;
$color--blue4: #f0f5ff;
$color--blue5: #0070e0;
$color--shadeblue1: #31a8e5;
$color--navy: #37539d;
$color--shadenavy: #324b8f;
$color--navy2: #769bf9;
$color--green1: #9fce06;
$color--green2: #b7ce6b;
$color--green3: #bfce8c;
$color--red1: #ff6565;
$color--red2: #ffb3b3;
$color--red3: #ffd9d9;

$color-body-bg: #e5e5e5;

$gradient--main: (
  $color--default-blue 0%,
  $color--navy 100%
);
$gradient--secondary: (
  $color--navy2 3.26%,
  $color--navy 100%
);
