.react-datepicker__time-container {
  border: none !important;
  width: 110px !important;
}
.react-datepicker__header--time {
  padding: 0 !important;
  border-style: none !important;
}
.react-datepicker__header {
  border: none !important;
}
.react-datepicker__time-box {
  width: 110px !important;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 105px !important;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}
li.react-datepicker__time-list-item {
  top: calc(50% - 25px / 2);
  @extend .fs-body1;
  @extend .text--gray5;
  display: flex;
  align-items: center;
  transition: 0.5s;
  &:hover {
    color: $color--navy !important;
    background: white !important;
  }
  &--selected {
    color: $color--navy !important;
    background: white !important;
  }
  &--disabled {
    display: none;
  }
}

.fas {
  color: $color--gray8 !important;
}
.btn--xs {
  width: 26px;
  background: white;
}
.icon-img-size {
  height: 50px;
}
