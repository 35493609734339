.tabs-container.auto-width {
  .nav-tabs {
    li {
      width: auto;
    }
  }
}

.reservation-item {
  >div {
    svg {
      width: 64px;
      padding: 12px;
    }
  }
  .c {
    text-align: center;
  }
  .col-2,
  .col {
    padding-left: 15px;
    padding-right: 15px;
  }
  button+button {
    margin-left: 10px;
  }
}

div.filters {
  .element--clickable {
    white-space: nowrap;
  }
}

.actions-panel {
  position: fixed;
  // right: 8px;
  left:auto;
  bottom: 19px;
  width: 100%;
  z-index: 90;
}

.reservations {
  .tb-head {
    top: 65px;
    z-index: 70;
  }
  .tb-row:not(.title):not(.tb-head) {
    z-index: 60;
  }
  .tb-row:not(.title):not(.tb-head):hover {
    z-index: 69;
  }
}

.reservations .tb-grp .title {
    top: 113px;
    z-index: 68;
}