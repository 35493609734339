/* ---- Buttons ----*/
.button--base {
  background: white;
  min-width: 110px !important;
  width: 160px;
  height: 40px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  display: inline-block;
  cursor: pointer;
  color: white !important;
  padding: 5px 10px;
  text-decoration: none;
  @extend .fs-btn2;
  &:focus {
    outline: none !important;
  }
  border: unset;
}

.button--large {
  @extend .button--base;
  width: 200px;
}

.button-outline--large {
  @extend .button--large;
  background: inherit;
  border: 1px solid #ffffff;
}
.button-outline--large-transparent {
  @extend .button--large;
  background: none;
  border: 2px solid #ffffff;
}

.button--warning {
  @extend .button--large;
  background: $color--red1;
}

.button--success {
  @extend .button--large;
  background: $color--default-blue;
}

.button--success-extra-large {
  @extend .button--success;
  width: 354px;
}

.button--disabled {
  background: $color--gray6;
}

.button-warning--modal {
  @extend .button--success;
  background: $color--red1;
  width: 140px;
  height: 35px;
  font-size: 15px;
  padding: 3px 8px;
}

.button-success--modal {
  @extend .button--success;
  height: 35px;
  font-size: 15px;
  padding: 3px 8px;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}