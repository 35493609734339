/*---- sidebar ----*/
.logo {
  width: 100%;
  text-align: center;
}

.bar-container--vertical {
  border: 1px solid $color--gray3;
  border-left: 0px;
  z-index: 101;
  background-color: #fff;
  
  overflow-y: auto;
  max-height: 100vh;
  overflow-x: hidden;
  min-width: 90px;

  /* Vertical left main menu with logo at top and items that scroll vertically */
  >div {
    height: 100%;
    display: flex;
    flex-flow: column;
    >ul {
      overflow: auto;
      flex: 1 1 auto;
      overflow-x: hidden;
      flex-direction: column;
      display: flex;
      height: 100%;
      justify-content: flex-start;
    }
  }

  img {
    margin: 10px auto;
    cursor: pointer;
  }

  li {
    & > * {
      // padding: 35px 45px;
      padding: 20px 25px;
      display: block;
      svg path {
        fill: #d6d6d6;
      }
    }
    div {
      p {
        color: #d6d6d6;
      }
    }
    a {
      transition: 0.5s;
      cursor: pointer;
      svg path {
        fill: #37539d;
      }
      &.selectedElement,
      &.active,
      &:hover {
        @extend .bg--grad10;
        color: white !important;
        svg path {
          transition: 0.5s;
          fill: white;
        }
      }
      text-decoration: none;
      color: inherit;
    }
  }
  &.notification-active{
    li {
      a{
        &:not(.notifications){
          cursor: pointer;
          background: transparent !important; 
          color:#37539d !important;
          svg path {
            fill: #37539d;
          }
          &:hover {
            @extend .bg--grad10;
            color: white !important;
            svg path {
              transition: 0.5s;
              fill: white;
            }
          }
        }
        &.notifications{
          @extend .bg--grad10;
          color: white !important;
          svg path {
            transition: 0.5s;
            fill: white;
          }
      }
      }
    }
  }
}

// TODO: improved upon when strategy for multiple screens defined
@media screen and (max-width: 1200px) {
  .small-sidebar .bar-container--vertical {
    width: 90px;
  }
  .small-sidebar li a,
  .small-sidebar li > div {
    padding: 20px 0px;
  }
  .small-sidebar li p {
    padding-bottom: 0px !important;
  }
  .small-sidebar li a {
    font-size: 0.8rem;
    border-bottom: 1px solid $color--gray4;
  }

  .small-sidebar .logo {
    width: 90px;
  }
  .small-sidebar .logo img {
    width: inherit;
  }
}

.posted-event-box{
  border: 2px solid #e1e3e9;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 12px;
  h2{
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p{
    padding: 12px 0;
  }
  .event-row{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e9ee;
    padding: 12px 0;
    flex-direction: row;
    justify-content: flex-start;

    &:last-child{
      border-bottom: 0px;
    }
    .event-icon{
      width: 35px;
      height: 35px;
      margin-right: 10px;
      display: flex;
      font-size: 0.6rem;
      flex-direction: column;
      justify-content:center;
      align-items:center;
     
      + div{
        max-width: calc(100% - 50px);
      }
      svg{
        width: 35px;
        height: 35px;
      }
      &.tennis svg,
      &.badminton svg{
        margin-left: 5px;
      }
    }
    p{
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      margin: 0;
      padding: 0;
      span{
        color:#a5aaad;
      }
    }
  }
}
.social-chanel-box{
  border: 2px solid #e1e3e9;
  background-color: #f5f6f8;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 12px;
  margin-top: 20px;
  
  h2{
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .status-text{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e9ee;
    padding: 12px 0;
    flex-direction: row;
    justify-content: space-between;
    &:last-child{
      border-bottom: 0;
    }
    > div{
      display: flex;
      align-items: center;
      position: relative;
    }
    img{
      width: 37px;
      height: 37px;
    }
    h3{
      font-size: 0.8rem;
      font-weight: normal;
      line-height: 100%;
    }
    p{
      font-size: 0.9rem;
      font-weight: normal;
    }
    b{
      font-size: 0.9rem;
      margin-right: 5px;
    }

    &.success{
      b{
        color:#28a745
      }
      >div{
        &::after{
          content:"";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' enable-background='new 0 0 64 64'%3E%3Cpath d='M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50 l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z' fill='%2343a047'/%3E%3C/svg%3E");
          background-color: #fff;
          width:20px;
          height: 20px;
          display: block;
          position: absolute;
          border-radius: 50%;
          border: 1px solid white;
          left: 20px;
          bottom: -3px;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
       }
      }
    }
    &.error{
      b{
        color:#e33545
      }
      >div{
        &::after{
          content:'';
          background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 248' width='128' height='248'%3E%3Cdefs%3E%3Cimage width='52' height='214' id='img1' href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAADWBAMAAABs2rxRAAAAAXNSR0IB2cksfwAAADBQTFRF////////////////////////////////////////////////////////////////Tq+4QAAAABB0Uk5TAP9w4LBgUNBAMMAgoJCAEAg+7SUAAAGMSURBVHic7djrUQIxEADgrHcwgB5IBdKB2IElYCd2oC1YgWMFlCBWoB0IOv4BZ84O9CCPTcJmb8wwzo2X/IBjPjYJRy6PBWEKnFYv31/42VyN1Xvp09HIfGe0dGkssJQO2aINsAdYZF+ACFJhQASpMKCCZBhQQTIMyKBdGJBBuzCeqPq2NYLIhiSdL4Cub1sjiKsFSZ01BJqqGuMo0FTV2J/SxTJAXQj1vbrtByemGw25Uc2nXj9AN5FD9N/SwzUp2Sc7AySyqd8j6X7Gzr1tpKcZISfv8UtbC+m4S1BZt3NoI62me1K81e6+EtnE7GATObQ32vKNJuaAkEiIsxdNzCErkUPeGayzRmLOsA2hYeaQPLjXns05chpTGQRFeWHR49QmJkVg16gzEiYdgWE6H2HItDa/9ElXiQkOJFnnK7Zpk1cSNYDkECgJCqaqmGFzd2to8OEQM7DDjwPzfEUluIrco+eJprgl4NCLr/eDdRfbuo+K+1MiVyJmREXO2LHTsjEzw/5i7sVHWfwAv3YW6F+DvcgAAAAASUVORK5CYII='/%3E%3C/defs%3E%3Cstyle%3E%3C/style%3E%3Cuse href='%23img1' x='38' y='17'/%3E%3C/svg%3E");
          background-size: 45%;
          background-repeat: no-repeat;
          background-position: center;
          background-color:#e33545;
          text-align: center;
          color:white;
          font-weight: bold;
          width:19px;
          height: 19px;
          line-height: 15px;
          font-size: 13px;
          display: block;
          position: absolute;
          border-radius: 50%;
          border: 2px solid white;
          left: 20px;
          bottom: -3px;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
       }
      }
    }
    &.not-set{
      b{
        color:yellow;
      }
      
    }
  }
}