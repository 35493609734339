.login-form {
  .logo {
    width: 104px;
    height: 154px;
  }
  .form-container {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    width: 100%;
    padding: 35px 60px;
  }

  .input-group {
    width: 15px;
    height: 10px;
    right: 22px;
    top: 10px;
  }
  .form-container .button--success {
    width: 170px;
    border: none;
  }
  .input--text,
  .input-group,
  .far,
  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .input--text {
    padding: 26px;
    width: 90%;
    border: 1px solid transparent;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    transition: 0.3s;
    &:focus {
      outline-color: white;
      color: white;
    }
    &:focus + .input-group svg path {
      fill: white;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: white !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .error-message {
    width: 90%;
  }

  @media only screen and (min-width: 700px) {
    .form-container {
      max-width: 370px;
      height: 480px;
    }
  }
}
