.dropdown-container {
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
  }
  
.dropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }

.dropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #fff;
}

.dropdown-item {
    padding: 5px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #9fc3f870;
}

.dropd-up-inset {
  inset: auto auto 85px -10px !important;
}