.slide-in{
    background-color: #fff;
    z-index: 2;
    width: 382px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .slide-in-first-level{
        padding: 22px 0px;
        background-color: #fff;
        z-index: 3;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        width: 100%;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
        .banner{
            padding: 0;
            height: auto;
        }
    }
    .slide-in-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 0 0 auto;
        padding: 0 22px;

        h1{
            color: #3d5298;
            font-size: 1.75rem;
            font-weight: bold;
        }
        .filter-btn{
            width: 22px;
            height: 22px;
            border-radius: 3px;
            border:1px solid #d5d8e0;
            margin: 5px 4px;
            cursor: pointer;
            background-size: cover;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
            &:hover{
                border:1px solid #1f2b32;
            }
        }
        .header-filters{
            display: flex;
        }
        .filter-mail{
            background-image: url("data:image/svg+xml,%3Csvg class='svg-icon' style='width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M170.666667 341.333333l341.333333 213.333334 341.333333-213.333334-341.333333-213.333333-341.333333 213.333333m768 0v426.666667a85.333333 85.333333 0 0 1-85.333334 85.333333H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V341.333333c0-31.146667 16.64-58.026667 41.386667-72.96L512 27.306667l385.28 241.066666c24.746667 14.933333 41.386667 41.813333 41.386667 72.96z' fill='' /%3E%3C/svg%3E");
        }
        .filter-sort{
            background-image: url("data:image/svg+xml,%3Csvg fill='none' height='120' viewBox='0 0 24 24' width='120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11 8h10m-10 0c0 1.65685-1.34315 3-3 3s-3-1.34315-3-3m6 0c0-1.65685-1.34315-3-3-3s-3 1.34315-3 3m0 0h-2m10 8h-10m10 0c0-1.6569 1.3431-3 3-3s3 1.3431 3 3m-6 0c0 1.6569 1.3431 3 3 3s3-1.3431 3-3m0 0h2' stroke='%2309090b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
    }
    .publish-timestamp{
        color:#899093;
        font-size: 0.83em;
        line-height: 115%;
    }
    .read-status-filter-c{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex: 0 0 auto;
        padding: 0 18px;
        .read-status-filter-btn{
            color:#1f2b32;
            padding: 0px 7px;
            border-radius: 8px;
            margin: 8px 5px;
            font-size: 0.712rem;
            cursor: pointer;
            &.active-filter,&:hover{
                color:#3d5298;
                background-color: #d8dcea;
            }
        }
    }
    .scroll-content{

        height: 100%;
        overflow-y: scroll;
        
    }
    .notifications-list{
        flex: 1 1 auto; /* div B should fit the remaining space */
        overflow-y: auto;
        flex: 1 1 auto;
        overflow-y: auto;
        .title{
            padding: 0 22px;
        }
  
        .icon-container{
            width: 48px;
            height: 48px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            svg{
                width: 34px;
                height: 34px;
                fill:#fff !important;
            }
            &.success{
                &::after{
                   content:"";
                   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' enable-background='new 0 0 64 64'%3E%3Cpath d='M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50 l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z' fill='%2343a047'/%3E%3C/svg%3E");
                   background-color: #fff;
                   width:20px;
                   height: 20px;
                   display: block;
                   position: absolute;
                   border-radius: 50%;
                   border: 1px solid white;
                   right: -3px;
                   bottom: -3px;
                   box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
                }
            }
            &.success.error,
                &.error.error{
                &::after{
                   content: "";
                   background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 248' width='128' height='248'%3E%3Cdefs%3E%3Cimage width='52' height='214' id='img1' href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAADWBAMAAABs2rxRAAAAAXNSR0IB2cksfwAAADBQTFRF////////////////////////////////////////////////////////////////Tq+4QAAAABB0Uk5TAP9w4LBgUNBAMMAgoJCAEAg+7SUAAAGMSURBVHic7djrUQIxEADgrHcwgB5IBdKB2IElYCd2oC1YgWMFlCBWoB0IOv4BZ84O9CCPTcJmb8wwzo2X/IBjPjYJRy6PBWEKnFYv31/42VyN1Xvp09HIfGe0dGkssJQO2aINsAdYZF+ACFJhQASpMKCCZBhQQTIMyKBdGJBBuzCeqPq2NYLIhiSdL4Cub1sjiKsFSZ01BJqqGuMo0FTV2J/SxTJAXQj1vbrtByemGw25Uc2nXj9AN5FD9N/SwzUp2Sc7AySyqd8j6X7Gzr1tpKcZISfv8UtbC+m4S1BZt3NoI62me1K81e6+EtnE7GATObQ32vKNJuaAkEiIsxdNzCErkUPeGayzRmLOsA2hYeaQPLjXns05chpTGQRFeWHR49QmJkVg16gzEiYdgWE6H2HItDa/9ElXiQkOJFnnK7Zpk1cSNYDkECgJCqaqmGFzd2to8OEQM7DDjwPzfEUluIrco+eJprgl4NCLr/eDdRfbuo+K+1MiVyJmREXO2LHTsjEzw/5i7sVHWfwAv3YW6F+DvcgAAAAASUVORK5CYII='/%3E%3C/defs%3E%3Cstyle%3E%3C/style%3E%3Cuse href='%23img1' x='38' y='17'/%3E%3C/svg%3E");
                   background-color: rgb(255, 187, 0);
                   background-size: 45%;
                   background-repeat: no-repeat;
                   background-position: center;
                   color: #fff;
                   width:20px;
                   height: 20px;
                   line-height: 16px;
                   display: block;
                   position: absolute;
                   border-radius: 50%;
                   border: 2px solid white;
                   right: -3px;
                   bottom: -3px;
                   box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
                }
            }
            &.error.error{
                background-color: #e33545;
            }
            &.not-set{
                &::after{
                   content:"";
                   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' enable-background='new 0 0 64 64'%3E%3Cpath d='M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50 l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z' fill='%2343a047'/%3E%3C/svg%3E");
                   background-color: #fff;
                   width:20px;
                   height: 20px;
                   display: block;
                   position: absolute;
                   border-radius: 50%;
                   border: 1px solid yellow;
                   right: -3px;
                   bottom: -3px;
                   box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
                }
            }
        }
        .info-container{
            width: calc(100% - 58px);
            padding-left: 8px;
        }

        .notification-element{
            cursor: pointer;
            position: relative;
            padding: 18px 22px;
            &:hover, &.active{
                background-color: #e5e5e5;
            }
            &.hide{
                display: none;
            } 
            &.not-seen{
                &::after{
                    content: '';
                    width: 12px;
                    height: 12px;
                    display: block;
                    position: absolute;
                    right:14px;
                    background-color: #3d5298;
                    border-radius: 50%;
                    top: calc(50% - 6px);
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
                }
                &.active{
                   &::after{
                    display: none;
                   } 
                }
            }
            p{
                font-size: 0.88em;
                margin-bottom: 0;
                line-height: 135%;
            }
            > div{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            
            &:nth-child(3n-1) {
                .icon-container{
                    background-color: #5db1ef !important;
                }
        
            }
            &:nth-child(3n-2) {
                .icon-container{
                    background-color: #6cbc72 !important;
                }
            }

            &:nth-child(3n-3) {
                .icon-container{
                    background-color: #4e555b !important;
                }
            }
            &:nth-child(3n-4) {
                .icon-container{
                    background-color: #aa1a2c !important;
                }
            }
        }
        
    }
    
    .slide-in-second-level{
        position: absolute;
        height: 100vh;
        display: flex;
        top: 0;
        max-width: 382px;
        width: 100%;
        transition: all 0.5s;
        background-color: #fff;
        flex-direction: column;
        &:empty{
            left: 0px;
            transition: all 0.2s;
            z-index: -1;
        }
        &:not(:empty){
            left: 382px;
        }
        .slide-in-content{
            width: 100%;
            flex: 1 1 auto;
            overflow-y: auto;
            z-index: 1;
        }
        .modal-header{
            background-color: white;
            position: absolute;
            width: inherit;
            box-sizing: border-box;
            top: 0;
            z-index: 2;
        }
    }
}