.modal {
	overflow-y: auto;
}

.video {
	max-height: 75vh !important;
}

.custom-loader-spinner-center {
	display: flex;
	justify-content: center;
}

.modal__custom-event {
	margin: auto;
	width: 70%;
	&--dialog-width {
		min-width: 600px !important;
		max-width: 1200px !important;
		min-height: 100%;
	}
	&--header-height {
		height: 6em !important;
	}

	&--fs {
		@include fs(normal, 17px, 25px);
		@media (max-width: 1500px) {
			@include fs(normal, 14px, 22px);
		}
		@media (max-width: 1100px) {
			@include fs(normal, 13px, 17px);
		}
	}
}
.modal-responsive {
	width: 90%; 
	max-width: 600px;
	margin: auto;
}

.modal-responsive .modal-footer {
	position: sticky;
	bottom: 0;
	background-color: white; 
	z-index: 10; 
	box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); 
}

.modal__post-editor {
	&--body-height {
		height: 42em !important;
	}
}

.modal__print-poster {
	&--body-height {
		height: 10em !important;
	}
}

.modal--first {
	z-index: 10000 !important;
}

.modal--overflow {
	overflow: visible;
}

.channels-popup {
	transition: all 0.5s ease-out;
	left: 40% !important;
	height: auto !important;
	background: none;
	width: 33%;
}

@media screen and (max-width: 1500px) {
	.channels-popup {
		left: 25% !important;
		width: 50% !important;
	}
}

.match-time {
	border-top: 2px solid;
}

.channel-list__border {
	border-radius: 6px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

.modal-field-label {
	@extend .fs-body2;
	margin-bottom: 0.5rem;
}

.modal-field-value {
	margin-bottom: 17px;
	display: block;
	width: 100%;
	min-height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal-content {
	background-color: $color--gray1;
}

.modal-subtitle {
	@extend .fs-h2;
	margin-bottom: 15px;
}
