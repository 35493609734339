.events-tab-header {
  .nav-tabs {
    top: 117px !important;
  }
}

.dashboard-tab--header {
  .nav-tabs {
    top: 0px !important;
  }
}
.tabs-container {
  .nav-tabs {
    border: none;
    padding: 0 5px 0 5px;
    box-shadow: 0px 5px 5px $shadow-color;
    position: sticky;
    z-index: 70;
    @extend .bg--gray1;
    .tabs-header {
      @extend .bg--gray1;
    }
    li {
      padding: 0 5px 0 5px;
      background: inherit;
      width: 50%;
      text-align: center;
      margin-bottom: 0;
    }
    a.tabs-header {
      @extend .fs-caption1;
      border: none;
      @extend .text--gray7;
      &.active {
        @extend .bg--gray1;
        @extend .text--navy;
        border-bottom: 3px solid $color--navy;
      }
    }
  }
  .tabs-content {
    height: 80%;
    > div {
      height: 100%;
    }
  }
}
