.active-list {
  li {
    cursor: pointer;
    svg path {
      fill:  black;
    }
    a {
      display: block;
      text-decoration: none;
      color: black;
      padding: 11px 8px;
      &.selected,
      &:hover {
        .settings--p__navy {
          @extend .text--navy;
        }
        transition: 0.5s;
        background: white;
        border-right: 1px solid $color--navy;
        svg path {
          fill: $color--navy;
        }
      }
    }
  }
}

.content-height {
  height: calc(100vh - 100px);
}

.button-left {
  border: none;
  color: #ffffff;
}

.input-group-text {
  background-color: white;
  width: inherit;
  text-align: center;
  align-content: center;
}

.image-button-div {
  background-color: #37539D;
  width: fit-content;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-top-right-radius: 8px;
  height: 50px;
  vertical-align: center;
  position: absolute;
  bottom: 0;

  input[type="file"] {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 50%;
  }
}
.button--success-no-bg {
  @extend .button--success;
  width: fit-content;
  background: none;
  margin-top: 3.5px;
}
.text-span {
  margin-right: 10px;
}

.company-image-div {
  position: relative;
  height: 190px;

  .company-image {
    width: 100%;
    height: 189px;
  }
}

.update-company-info-div {
  height: 60px;
  margin-top: 10px;
  padding-left: 20px;
  background-color: #FFFFFF;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
.update-reservations-info-div {
  height: 60px;
  margin-top: 10px;
  padding-left: 20px;
  background-color: #FFFFFF;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.feature-flex {
  display: -webkit-flex; /* Safari */
  display: flex;

  .feature-space {
    width: 20px;
  }
  .half-screen-space {
    width: 50%;
  }
}
.feature-div {
  width: 280px;
  text-align: left;
  align-items: center;
  justify-content: center;

  .feature-switch {
    float: right;
    vertical-align: middle;
    background: #FFFFFF;
    border: 1px solid #F0F1F5;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .feature-input {
    @extend .feature-switch;
    width: 50px;
    height: 25px;
    text-align: center;
  }
}

.feature-column {
  width: 280px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}

.feature-img {
  margin-right: 15px;
  vertical-align: middle;
}

.feature-name {
  display:inline;
  width: 225.9px;
  height: 13px;
  left: 15px;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  vertical-align: middle;
}

.feature-input {
  margin-bottom: 20px;
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: red;
  }
}

.company-attributes-last-div {
  margin-bottom: 90px;
}

.no-border-button {
  border: transparent;
}

.custom-header-text {
  text-align: center;
  margin-top: 5px;
}
