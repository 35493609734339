.header {
  line-height: 1 !important;
  width: 100%;
  flex-shrink: 0;

  h2 {
    margin-bottom: 0;
  }

  p {
    mix-blend-mode: normal;
    opacity: 0.8;
  }

  button {
    text-align: center;
  }

  div.for-icon {
    padding-left: 0.5rem;
    padding-right: 1rem;
    svg path {
      fill: white;
    }
  }
}

.header.reservations {
  img {
    filter: invert(1);
  }
}

