/*promo element*/
$promo-container-width: 100%;

.promo-item {
  box-shadow: 0px 3px 5px $shadow-color;
  border-radius: 10px;
  height: auto;
  width: $promo-container-width;
  &--header {
    padding-top: 1rem;
    width: 87%;
    .row {
      height: 30px;
    }
  }
  &__action-icons {
    border-radius: 150px;
  }
  &__match-times {
    min-width: 150px;
  }
  &--inner {
    border-radius: 10px;
  }
}

.promo-item,
.promo-page {
  .promotype {
    font-size: 3rem;
    font-weight: bold;
  }
  .visible_winner {
    height: auto;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }
  .invisible_winner {
    height: 0px;
    opacity: 0;
  }

  .winner {
    font-weight: bold;
    font-size: 4rem;
    border: none;
    max-width: 100%;
  }
  .button--success:hover {
    box-shadow: 0px 0px 20px rgba(81, 180, 245, 0.2);
  }
  .cast-icon {
    margin-left: 1rem;
    width: 1rem;
    margin-top: -2px;
  }
}

.promo-page {
  .promo-page-container {
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    width: 100%;
    padding: 35px 60px;
  }

  @media only screen and (min-width: 700px) {
    .form-container {
      max-width: 370px;
      height: 480px;
    }
  }
  .logo {
    width: 60px;
  }
}

.confirm-repick {
  z-index: 112;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 500px;
  background: rgba(255, 255, 255, 1);
  display: none;
  flex-direction: column;
  overflow: hidden;
}
.confirm-repick.open {
  display: flex;
}
.confirm-repick > .text {
  flex: 1;
}

.cr-overlay {
  z-index: 111;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  &.open {
    display: block;
  }
}
