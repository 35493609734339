.grid-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 10px;
	column-gap: 20px;
	row-gap: 20px;
}
.grid-item {
	flex: 1 0 28%;
	padding: 20px;
}

@media (max-width: 500px) {
	.grid-item {
		flex: 1 0 50%;
	}
}
