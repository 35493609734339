.imageUploader {
  cursor: pointer;
  background-color: #d5d8e0;

  &__removeButton {
    width: 24px;
    height: 24px;
    position: absolute;
    right: -10px;
    top: -10px; 
  }
}