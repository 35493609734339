.checkbox-button {
  display: flex;
  flex-direction: row;
  margin: 4px;
  margin-left: 0px;
  background-color: #f1f1f1;
  border-radius: 10px;
  border: 2px solid #dadee2;
  overflow: auto;
  color: #000000;
}

.checkbox-button label {
  margin: 0;
  width: 100%;
  cursor: pointer;
}

.checkbox-button label svg {
  margin-right: 1px;
}

.checkbox-button label input {
  display: none;
}

.checkbox-button input:checked {
  border-radius: 10px;
  border: 2px solid #1e4798;
}

.tip {
  display: flex;
  align-items: center;
} 

.tip-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 10000;
  width: 350px;
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}


.tip:hover + .tip-content {
  display: flex;
}


.tl-tip {
  position: absolute;
  right: 0;
  top: 100%;
  left: auto;
  transform: translate(-90%, -145%);
} 

.tl-tip-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 20000;
  width: 300px;
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.switch-container:hover .tl-tip-content {
  display: block;
}


.switch-container {
  position: relative;
}
.ch-box  {
  transform: scale(1.5); 
}