// --------------------------Media Query Mixin---------------------------

@mixin mq($break) {
  @if $break == "small" {
    @media (min-width: $brkpoint-sm) and (max-width: $brkpoint-md) {
      @content;
    }
  } @else if $break == "medium" {
    @media (min-width: $brkpoint-md + 1) {
      @content;
    }
  } @else if $break == "large" {
    @media (min-width: $brkpoint-lg) {
      @content;
    }
  } @else if $break == "xl" {
    @media (min-width: $brkpoint-xl) {
      @content;
    }
  } @else {
    @error "'#{$break}' is not a valid query!";
  }
}

@mixin fs($fw, $fs, $lh, $c: $color--gray9) {
  font-weight: $fw !important;
  font-size: $fs !important;
  line-height: $lh !important;
  color: $c !important;
}
