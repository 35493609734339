.dropdown-menu {
  min-width: 110px !important;
  transform: translate3d(0px, 40px, 0px) !important;
  max-height: none;
  overflow-y: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.dropdown-item:hover {
  background: white;
}

.dropdown-item {
  white-space: normal !important;
  padding: 10px 5px !important;
}
