$bottom-bar-height: 60px;
$bottom-bar-margin-top: 3px;

.rightSide__bottomBar {
  height: $bottom-bar-height;
  margin-top: $bottom-bar-margin-top;
  padding-left: 20px;
  background-color: #FFFFFF;
  padding-top: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.1);
}

.rightSide__elementData {
  height: calc(100% - #{$bottom-bar-height} - #{$bottom-bar-margin-top});
}

.smp-tab > div{
  // Button size and paddings
  padding-bottom: 51px;

  &:first-child {
    height: 100%;
  }
}