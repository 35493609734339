.event-dialog {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  z-index: 90;
  background-color: #fff;
  .event-count {
    border-radius: 50%;
    padding: 10px 15px;
    margin: 5px;
  }
}
.events-tab-header {
  bottom: 83px;
  @media (min-width: 817px) {
    bottom: 66px;
  }
}
