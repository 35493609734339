
.Toastify > div{
  > div{
    // opacity: 0;
    // z-index: -10;
    // bottom: -999px;
  }
  // .Toastify__toast{
    
  // }
  
}
.Toastify__toast.Toastify__toast--default {
  transition: 0.3s ease all;
  .Toastify__progress-bar.Toastify__progress-bar--controlled.Toastify__progress-bar--default.success-progress-bar {
    animation-play-state: running !important;
    width: 100%;
  }

  .Toastify__progress-bar.Toastify__progress-bar--animated {

    animation-play-state: running !important;
    width: 100%;
  }

  &.hide {
    
    // opacity: 0;
    // z-index: -10;
    &.Toastify__toast{
      min-height: unset;
      padding: 2px;
      margin-bottom: 0px;
      width:380px
    }
    .Toastify__toast-body{
      display: none;
    }
  }

  &.visible {
    opacity: 1;
    z-index: 10;
    bottom:auto;
  }

  &.completed {
    

    &:not(.error) {

      .Toastify__progress-bar {
        animation: Toastify__trackProgress_custom_completed linear 1 forwards;
        animation-duration: 2000ms !important;
        animation-play-state: running !important;
      }
    }
  }

  &.error {
    .Toastify__progress-bar.Toastify__progress-bar--animated {
      display: none;
    }
  }

  .Toastify__progress-bar--default {
    background: #28A745 !important
  }
}


.Toastify__toast--default {
  text-align: center;
  width: 650px;
  margin-bottom: 55px;
}

.Toastify__progress-bar {
  left: 0;

  &.success-progress-bar {
    &::before {
      background-color: green;
      content: "";
      height: 4px;
      display: block;
    }
  }



}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress_custom {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress_custom_completed {
  0% {
    transform: scaleX(0.983);
  }

  98% {
    transform: scaleX(0.998);
  }

  100% {
    transform: scaleX(1);
  }
}
@keyframes completed {
  0% {
    
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

// .Toastify__progress-bar--animated,
.Toastify__progress-bar {
  animation: Toastify__trackProgress_custom reverse linear infinite;
  // animation: Toastify__trackProgress_custom linear 1 forwards !important;
}

// @keyframes Toastify__trackProgress {
//       0%{
//         transform: scaleX(0);
//       }
//       100%{
//         transform: scaleX(1);
//       }
//     }

/*******/
// @keyframes Toastify__trackProgress {
//     0%{
//       transform: scaleX(1);
//     }
//     100%{
//       transform: scaleX(0);
//     }
//   }

//   .Toastify__progress-bar {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 5px;
//     // z-index: $rt-z-index;
//     opacity: 0.7;
//     background-color: rgba(255,255,255,.7);
//     transform-origin: left;

//     &--animated {
//       animation: Toastify__trackProgress linear 1 forwards;
//     }

//     &--controlled {
//       transition: transform .2s;
//     }

//     &--rtl {
//       right: 0;
//       left: initial;
//       transform-origin: right;
//     }

//     &--default{
//     //   background: $rt-color-progress-default;
//     }

//     &--dark{
//     //   background: $rt-color-progress-dark;
//     }

//     &--info{}
//     &--success{}
//     &--warning{}
//     &--error{}
//   }