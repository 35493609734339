//------------------font-styles----------------------------
.fs-h1 {
  @include fs(bold, 34px, 41px);
}

.fs-h2 {
  @include fs(bold, 22px, 28px);
}

.fs-h3 {
  @include fs(bold, 17px, 22px);
}
.fs-h4 {
  @include fs(bold, 15px, 20px);
}
.fs-h5 {
  @include fs(bold, 11px, 13px);
}

.fs-btn1 {
  @include fs(bold, 17px, 22px);
}

.fs-btn2 {
  @include fs(bold, 13px, 18px);
}
.fs-caption1 {
  @include fs(bold, 14px, 20px);
}
.fs-caption2 {
  @include fs(bold, 11px, 13px);
}

.fs-caption-error {
  @include fs(bold, 14px, 20px, $color--red1);
}

.fs-body1 {
  @include fs(normal, 17px, 25px);
}

.fs-body2 {
  @include fs(normal, 14px, 22px);
}
.fs-body3 {
  @include fs(normal, 11px, 17px);
}
.fs-body4 {
  @include fs(normal, 10px, 11px);
}
.fs-body-micro {
  @include fs(normal, 12px, 14px);
}

.text--white {
  color: $color--white !important;
}
.text--gray1 {
  color: $color--gray1 !important;
}
.text--gray2 {
  color: $color--gray2 !important;
}
.text--gray3 {
  color: $color--gray3 !important;
}
.text--gray4 {
  color: $color--gray4 !important;
}
.text--gray5 {
  color: $color--gray5 !important;
}
.text--gray6 {
  color: $color--gray6 !important;
}
.text--gray7 {
  color: $color--gray7 !important;
}
.text--gray8 {
  color: $color--gray8 !important;
}
.text--gray9 {
  color: $color--gray9 !important;
}
.text--gray10 {
  color: $color--gray10 !important;
}
.text--gray11 {
  color: $color--gray11 !important;
}
.text--yellow1 {
  color: $color--yellow1 !important;
}
.text--yellow2 {
  color: $color--yellow2 !important;
}
.text--yellow3 {
  color: $color--yellow3 !important;
}
.text--orange1 {
  color: $color--orange1 !important;
}
.text--orange2 {
  color: $color--orange2 !important;
}
.text--orange3 {
  color: $color--orange3 !important;
}
.text--blue1 {
  color: $color--blue1 !important;
}
.text--blue2 {
  color: $color--blue2 !important;
}
.text--blue3 {
  color: $color--blue3 !important;
}
.text--shadeblue1 {
  color: $color--shadeblue1 !important;
}
.text--navy {
  color: $color--navy !important;
}
.text--shadenavy {
  color: $color--shadenavy !important;
}

.text--green1 {
  color: $color--green1 !important;
}
.text--green2 {
  color: $color--green2 !important;
}
.text--green3 {
  color: $color--green3 !important;
}
.text--red1 {
  color: $color--red1 !important;
}
.text--red2 {
  color: $color--red2 !important;
}
.text--red3 {
  color: $color--red3 !important;
}

.bg--grad1 {
  background: linear-gradient(191.77deg, $gradient--main) !important;
}
.bg--grad2 {
  background: linear-gradient(225deg, $gradient--secondary) !important;
}

.bg--grad3 {
  background: linear-gradient(191.77deg, $gradient--secondary) !important;
}
.bg--grad4 {
  background: linear-gradient(194.66deg, $gradient--secondary) !important;
}

.bg--grad5 {
  background: linear-gradient(233.13deg, $gradient--main) !important;
}

.bg--grad6 {
  background: linear-gradient(213.47deg, $gradient--secondary) !important;
}

.bg--grad7 {
  background: linear-gradient(225deg, $gradient--main) !important;
}

.bg--grad10 {
  background: linear-gradient(213.47deg, $gradient--main) !important;
}

.bg--grad8 {
  background: linear-gradient(202.43deg, $gradient--secondary) !important;
}
.bg--grad9 {
  background: linear-gradient(185.06deg, $gradient--main) !important;
}
.bg--grad11 {
  background: linear-gradient(185.06deg, $gradient--secondary) !important;
}
.bg--gray1 {
  background: $color--gray1 !important;
}

.bg--gray3 {
  background: rgba(255, 255, 255, 0.2) !important;
}
.bg--gray4 {
  background: $color-body-bg !important;
}

.bg--gray5 {
  background: $color--gray5 !important;
}

.bg--gray6 {
  background: $color--gray6 !important;
}

.bg--red1 {
  background: $color--red1 !important;
}

.bg--blue1 {
  background: $color--blue5 !important;
}

.bg--blue2 {
  background: $color--default-blue !important;
}

.text--shadow {
  text-shadow: 0.1px 0 0.1px !important;
}

.opacity6 {
  opacity: 0.6 !important;
}

.element--clickable {
  cursor: pointer !important;
}


