.react-datepicker {
  font-size: 1em !important;
  &__month-container {
    border: 1px solid $color--gray4 $color--red3 !important;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07) !important;
    border-radius: 3px !important;
  }

  &__header {
    padding-top: 1em !important;
  }

  &__triangle {
    display: none;
  }
  &__popper {
    border: none !important;
  }
  &__header {
    background-color: white !important;
    border-bottom: 2px dashed lighten($color: grey, $amount: 25%) !important;
  }

  &__navigation {
    // outline: 1px solid $color--gray9;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    height: 24px !important;
    width: 24px !important;
    margin: 0.5em 0.5em;
    transition: all 0.5s;
    border: none !important;
    &--next {
      background: url(../../images/img/rightArrow.svg) no-repeat center !important;
    }
    &--previous {
      background: url(../../images/img/leftArrow.svg) no-repeat center !important;
    }
    &:active {
      transform: scale(1.2);
    }
  }

  &__current-month {
    @extend .fs-body1;
    text-align: center !important;
    color: $color--gray10 !important;
  }

  &__day-names {
    margin: 0.2rem 0.5em !important;
  }
  &__day-name {
    @extend .fs-caption2;
    text-transform: uppercase !important;
    color: $color--gray6 !important;
    width: 2.5em !important;
    line-height: 2.5em !important;
    margin: 0.5rem !important;
  }

  &__day {
    color: $color--gray10 !important;
    @extend .fs-body1;
    margin: 0.5rem !important;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    &--outside-month {
      color: $color--gray3 !important;
    }
    &--weekend:last-child {
      color: $color--red1 !important;
      &.react-datepicker__day {
        &--outside-month,
        &--disabled {
          color: $color--red3 !important;
        }
        &--selected {
          color: $color--white !important;
        }
      }
    }
    &--selected {
      @extend .bg--grad7;
      border-radius: 20px !important;
      color: $color--white !important;
    }
    &--disabled + &--weekend:last-child {
      color: $color--red3 !important;
    }
    &:hover {
      border-radius: 20px !important;
      background-color: $color--white !important;
      border-color: $color--navy;
    }
    &--disabled {
      color: $color--gray3 !important;
      &:hover {
        border-color: transparent !important;
      }
    }
  }
}

.react-datepicker__input-container {
  border: 1px solid $color--gray4;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  width: 100%;
  button,
  input {
    border: none;
    &:hover,
    &:active,
    &:focus {
      background-color: white;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  #calenderinputicon {
    background-color: white;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
