* {
  box-sizing: border-box;
}
html {
  height: 100% !important;
  margin: 0 !important;
}
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  background: $color-body-bg;
  font-family: $font-family--primary;
  overflow: auto;
}

.btn-info {
  background: $color--default-blue;
}

.btn-danger {
  background-color: $color--red1 !important;
  border-color: $color--red1 !important;
}

.bg-secondary {
  background: $color--gray1 !important;
}
.bg-light {
  background: $color-body-bg !important;
}

textarea {
  resize: none;
}

.row {
  margin: 0;
 
}
.col-2,
.col {
  padding: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
img,
ul,
li,
input[type="checkbox"] {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.backdrop {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vh;
  height: 100vh;
  opacity: 0.5;
}
.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 600px !important;
  height: 100vh;
  background: white !important;
  @include mq(xl) {
    max-width: 2000px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.highlight_on_hover {
  &:hover {
    @extend .bg--gray5;
  }
}
.position_absolute__right--5 {
  position: absolute;
  right: 5px;
}
.position_relative {
  position: relative;
}

.modal-backdrop {
  z-index: 940 !important;
}
.modal {
  z-index: 950 !important;
}
/*---- Scroll---*/
::-webkit-scrollbar {
  width: 20px;
  height: 48px;
  border: 4px solid $color--gray7;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color--gray7;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.flex-center {
  display: flex;
  align-items: center;
}
.limit-to-1line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.limit-to-2lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.limit-to-3lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.banner {
  background: transparent;
  padding: 0;
  margin: 0;
  height: 80px;
  width: 100%;
}

/* Table with grouped rows */
.tb-max {
  margin: 0;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: table;
  text-align: center;
  background-color: white;

  .tb-row {
    background-color: white;
    position: relative;
    &:hover:not(.tb-head):not(.title) {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.13);
    }
    div:first-child {
      text-align: left;
    }
    display: table-row;
    white-space: break-spaces !important;
    .actions {
      white-space: nowrap;
    }
    > div {
      display: table-cell;
      vertical-align: middle;
      border-bottom: 1px solid #dee2e6;
      border-right: 1px solid #f9f9f9;
      float: none;
      padding: 12px;
      .icon {
        width: 22px;
        height: 22px;
        margin: 0 12px 0 0;
        @media (min-width: 767px) {
          width: 28px;
        height: 28px;
        }
        @media (min-width: 968px) {
          width: 32px;
        height: 32px;
        }
        path {
          fill: black;
        }
      }
      .btn+.btn {
        margin-left: 6px;
      }
      .btn {
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
          border-radius: 4px;
        }
        width: 32px;
        height: 32px;
        >img {
          width: 20px;
          height: 20px;
        }
      }
      .details {
        @extend .fs-body-micro;
        @extend .text--gray8;
      }
    }
    &.tb-head>div {
      border-top: 1px solid #dee2e6;
      border-right: 1px solid #f3f3f3;
    }
  }

  .tb-head {
    background-color: $color--gray2;
    position: -webkit-sticky !important;
    position: sticky !important;
    color: $color--gray7;
    font-size: $font-size-table-header-small;
  }

  .tb-grp {
    display: table-row-group;
    .title {
      background-color: white;
      >div {
        border-right: none;
        padding: 10px 48px;
        @extend .fs-body3;
        @extend .text--gray7;
      }
      position: -webkit-sticky !important;
      position: sticky !important;
      text-align: left;
    }
    .tb-row:last-child>div {
      border-bottom: 2px solid #dee2e6;
    }
  }
}

.notification-table{
  height:calc(100vh - 100px);
}
.notifications-content{
  position: absolute;
  z-index: 72;
  width: 100%;
  left:-100%;
  top: 0;
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  

  .header.notifications{
    // @media (min-width: 767px) {
    //   max-width: 90%;   
    // }
    // @media (min-width: 968px) {
    //   max-width: 80%;
    // }
  }

  .d-flex.flex-column.panel-container{
    height: calc(100vh - 80px);
    overflow: auto;
    max-width: 80%;
    box-shadow: 5px 30px 10px #999;
    max-width: 100%;
    font-size: 0.7rem;
    position: relative;

    .actions-panel{
      bottom:0px !important;
      position: sticky;
    }
    // @media (min-width: 767px) {
    //   max-width: 90%;
    //   font-size: 0.9rem;
    // }
    // @media (min-width: 968px) {
    //   max-width: 80%;
    //   font-size: 0.8rem;
    // }

  }
  .d-flex1.bg-secondary.w-25.w-100{
    left: -100%;
  }
  &.active{
    left:0;
    z-index: 72;
    .d-flex1.bg-secondary.w-25.w-100{
      left:0;
    }
  }
  .container-fluid{
    background-color: transparent !important;
    background: transparent !important;
    position: relative;
    &::after{
      content: "";
    background-color: rgba(255, 255, 255, 0.0)!important;
    transition: all 0.5s ease-in;
      // background-color: red;
    }
  }
  &.active{
    .container-fluid{
      &::after{
        
        background-color: rgba(0, 0, 0, 0.3)!important;
        // background-color: purple !important;
        
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      
      top: 0;
      left: 0;
      position: fixed;
      z-index: -10;
        z-index: 1;
      }
    }
  }
}
.max-vh-100{
  max-height: 100vh !important;
}