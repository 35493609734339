/*Event element*/
.event-item {
  min-height: 80px;
  min-width: 900px;
  &--header {
    padding-top: 1rem;
    width: 87%;
    .row {
      height: 30px;
    }
  }
  &__team-icons {
    img {
      object-fit: contain;
      width: 24px;
      height: 24px;
    }
  }
  &__action-icons {
    border-radius: 150px;
    img {
      height: 24px;
      width: 24px;
    }
  }
  &__sport-type {
    width: 64px !important;
    height: 64px !important;
    & path {
      fill: black !important;
    }
  }
  &__name p, div {
    white-space: normal;
  }
  .icon-reservation {
    width: 64px !important;
    height: 64px !important;
    padding: 12px;
    & path {
      fill: black !important;
    }
  }
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid $color--default-blue;
}

.event-items {
  &__header {
    background: $color--gray2;
  }
  &__group-title {
    z-index: 69;
  }
  .bg-reservation {
    background-color: $color--blue4 !important;
  }
}

.event-items__group-title-events {
  top: 0px;
}

.events-data-status {
  text-align: center;
  margin-bottom: 40px;
  padding: 1rem;
}

.event-items__group-title-dashboard {
  top: 48px;
}

.eventlist-channels {
  .channel-item {
    display: grid;
    max-width: 41px;
    &:hover {
      z-index: 4;
    }
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:hover {
      z-index: 4;
    }
    &--move-left {
      margin-left: -39px !important;
    }
    &__name {
      position: absolute;
      display: none;
      min-width: 100%;
      &--move-single-left {
        margin-left: -24px !important;
      }
      &--move-double-left {
        margin-left: -21px !important;
      }
    }
    &__logo {
      display: flex;
      width: 40px;
      height: 40px;
      img {
        max-width: 100%;
      }
      z-index: 1;
    }
    &__more {
      width: 40px;
      height: 40px;
    }
    &:hover {
      .channel-item {
        &__name {
          z-index: 1;
          display: block;
          left: 0;
          bottom: 0;
          padding: 3px;
        }
        &__logo {
          img {
            -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
          }
        }
        &__more {
          background: $color--gray3 !important;
        }
      }
    }
  }
}
.eventlist-promos {
  &__wrapper {
    display: grid;
    width: 25px;
    height: 40px;
    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:hover {
      z-index: 4;
      .eventlist-promos__label {
        opacity: 1;
        top: 55px;
      }
    }
    &-active img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
  &__label {
    opacity: 0;
  }
  &__icon {
    &:hover {
      background: #f0f1f5 !important;
    }
    width: 40px;
    height: 40px;
    img {
      max-width: 100%;
      width: 100%;
    }
    &-offer {
      z-index: 1;
    }
    &-drink {
      z-index: 2;
    }
    &-activity {
      z-index: 3;
    }
    &:hover {
      img {
        opacity: 0.4;
      }
    }
  }
}
.event-item--published-instagram {
  .eventlist-socials__icon-instagram {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}
.event-item--published-facebook {
  .eventlist-socials__icon-facebook {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}
.event-item--published-sportcompass {
  .eventlist-socials__icon-sportcompass {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
}
.eventlist-socials {
  &__wrapper {
    display: grid;
    width: 25px;
    height: 40px;
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:hover {
      z-index: 4;
      .eventlist-promos__label {
        opacity: 1;
        top: 40px;
      }
    }
  }
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  &__label {
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  &__icon {
    &:hover img {
      -webkit-filter: grayscale(50%);
      filter: grayscale(50%);
    }
    width: 40px;
    height: 40px;
    img {
      max-width: 100%;
      width: 100%;
    }
    &-offer {
      z-index: 1;
    }
    &-drink {
      z-index: 2;
    }
    &-activity {
      z-index: 3;
    }
  }
}
.status-text {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  &-h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }
}

.dashboard-tab--header {
  .eventlist-channels .channel-item:nth-child(2) {
    z-index: 1;
  }
  .eventlist-channels .channel-item:nth-child(1) {
    z-index: 2;
  }
}

.channel-item--noimg {
  .channel-item__name {
    position: absolute;
    z-index: 12;
    display: block;
    left: 0;
    bottom: 0;
    padding: 3px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader-text {
  text-align: center;
  width: 700px;
  margin: 0 auto
}