.terms-of-use {
	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-2 {
		list-style-type: none;
	}
	.lst-kix_list_3-1 {
		>li {
			counter-increment: lst-ctn-kix_list_3-1;
			&:before {
				content: ""counter(lst-ctn-kix_list_3-1, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_3-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-4.start {
		counter-reset: lst-ctn-kix_list_3-4 0;
	}
	ol.lst-kix_list_3-4 {
		list-style-type: none;
	}
	.lst-kix_list_2-1 {
		>li {
			counter-increment: lst-ctn-kix_list_2-1;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
			}
		}
	}
	ul.lst-kix_list_1-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-6.start {
		counter-reset: lst-ctn-kix_list_2-6 0;
	}
	.lst-kix_list_3-0 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-0, lower-latin) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-0;
		}
	}
	ol.lst-kix_list_3-1.start {
		counter-reset: lst-ctn-kix_list_3-1 0;
	}
	.lst-kix_list_3-2 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-2, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-2;
		}
	}
	.lst-kix_list_4-0 {
		>li {
			counter-increment: lst-ctn-kix_list_4-0;
			&:before {
				content: ""counter(lst-ctn-kix_list_4-0, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_2-3.start {
		counter-reset: lst-ctn-kix_list_2-3 0;
	}
	ul.lst-kix_list_1-3 {
		list-style-type: none;
	}
	.lst-kix_list_3-5 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-5;
		}
	}
	ul.lst-kix_list_1-4 {
		list-style-type: none;
	}
	ul.lst-kix_list_1-1 {
		list-style-type: none;
	}
	.lst-kix_list_3-4 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-4;
		}
	}
	ul.lst-kix_list_1-2 {
		list-style-type: none;
	}
	ul.lst-kix_list_1-7 {
		list-style-type: none;
	}
	.lst-kix_list_3-3 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-3;
		}
	}
	ol.lst-kix_list_3-5 {
		list-style-type: none;
	}
	ul.lst-kix_list_1-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-6 {
		list-style-type: none;
	}
	ul.lst-kix_list_1-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-7 {
		list-style-type: none;
	}
	ul.lst-kix_list_1-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-8 {
		list-style-type: none;
	}
	.lst-kix_list_3-8 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-8;
		}
	}
	.lst-kix_list_2-0 {
		>li {
			counter-increment: lst-ctn-kix_list_2-0;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-0, lower-latin) ") ";
			}
		}
	}
	.lst-kix_list_2-3 {
		>li {
			counter-increment: lst-ctn-kix_list_2-3;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-3, decimal) ". ";
			}
		}
	}
	.lst-kix_list_3-6 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-6;
		}
	}
	.lst-kix_list_4-3 {
		>li {
			counter-increment: lst-ctn-kix_list_4-3;
			&:before {
				content: ""counter(lst-ctn-kix_list_4-3, decimal) ". ";
			}
		}
	}
	.lst-kix_list_3-7 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_3-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-7;
		}
	}
	ol.lst-kix_list_4-5.start {
		counter-reset: lst-ctn-kix_list_4-5 0;
	}
	ol.lst-kix_list_3-7.start {
		counter-reset: lst-ctn-kix_list_3-7 0;
	}
	ol.lst-kix_list_4-2.start {
		counter-reset: lst-ctn-kix_list_4-2 0;
	}
	ol.lst-kix_list_2-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-5 {
		list-style-type: none;
	}
	.lst-kix_list_4-4 {
		>li {
			counter-increment: lst-ctn-kix_list_4-4;
			&:before {
				content: ""counter(lst-ctn-kix_list_4-4, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_2-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-1 {
		list-style-type: none;
	}
	.lst-kix_list_4-8 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_4-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-8;
		}
	}
	.lst-kix_list_4-7 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_4-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-7;
		}
	}
	ol.lst-kix_list_4-1.start {
		counter-reset: lst-ctn-kix_list_4-1 0;
	}
	ol.lst-kix_list_4-8.start {
		counter-reset: lst-ctn-kix_list_4-8 0;
	}
	ol.lst-kix_list_3-3.start {
		counter-reset: lst-ctn-kix_list_3-3 0;
	}
	ol.lst-kix_list_2-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-0.start {
		counter-reset: lst-ctn-kix_list_4-0 0;
	}
	.lst-kix_list_2-5 {
		>li {
			counter-increment: lst-ctn-kix_list_2-5;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
			}
		}
	}
	.lst-kix_list_2-8 {
		>li {
			counter-increment: lst-ctn-kix_list_2-8;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
			}
		}
	}
	ol.lst-kix_list_3-2.start {
		counter-reset: lst-ctn-kix_list_3-2 0;
	}
	.lst-kix_list_2-2 {
		>li {
			counter-increment: lst-ctn-kix_list_2-2;
			&:before {
				content: ""counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
			}
		}
	}
	ol.lst-kix_list_2-4.start {
		counter-reset: lst-ctn-kix_list_2-4 0;
	}
	ol.lst-kix_list_4-7.start {
		counter-reset: lst-ctn-kix_list_4-7 0;
	}
	.lst-kix_list_2-6 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_2-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-6;
		}
	}
	.lst-kix_list_2-7 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-7;
		}
	}
	.lst-kix_list_2-4 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-4;
		}
	}
	ol.lst-kix_list_4-6.start {
		counter-reset: lst-ctn-kix_list_4-6 0;
	}
	ol.lst-kix_list_3-0.start {
		counter-reset: lst-ctn-kix_list_3-0 0;
	}
	ol.lst-kix_list_4-3.start {
		counter-reset: lst-ctn-kix_list_4-3 0;
	}
	ol.lst-kix_list_3-8.start {
		counter-reset: lst-ctn-kix_list_3-8 0;
	}
	ol.lst-kix_list_2-5.start {
		counter-reset: lst-ctn-kix_list_2-5 0;
	}
	.lst-kix_list_4-1 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_4-1, decimal) ". ";
			}
		}
	}
	.lst-kix_list_4-6 {
		>li {
			counter-increment: lst-ctn-kix_list_4-6;
			&:before {
				content: ""counter(lst-ctn-kix_list_4-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_2-2.start {
		counter-reset: lst-ctn-kix_list_2-2 0;
	}
	.lst-kix_list_4-5 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_4-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-5;
		}
	}
	.lst-kix_list_4-2 {
		>li {
			&:before {
				content: ""counter(lst-ctn-kix_list_4-2, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-2;
		}
	}
	ol.lst-kix_list_4-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-4.start {
		counter-reset: lst-ctn-kix_list_4-4 0;
	}
	ol.lst-kix_list_4-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-6.start {
		counter-reset: lst-ctn-kix_list_3-6 0;
	}
	ol.lst-kix_list_2-8.start {
		counter-reset: lst-ctn-kix_list_2-8 0;
	}
	ol.lst-kix_list_4-8 {
		list-style-type: none;
	}
	.lst-kix_list_1-0 {
		>li {
			&:before {
				content: "-  ";
			}
		}
	}
	ol.lst-kix_list_4-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}
	.lst-kix_list_3-1 {
	  list-style-type: none;
	}

	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}
	.lst-kix_list_1-1 {
		>li {
			&:before {
				content: "o  ";
			}
		}
	}
	.lst-kix_list_1-2 {
		>li {
			&:before {
				content: "\0025aa  ";
			}
		}
	}
	ol.lst-kix_list_2-0.start {
		counter-reset: lst-ctn-kix_list_2-0 0;
	}
	ol.lst-kix_list_4-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-7 {
		list-style-type: none;
	}
	.lst-kix_list_1-3 {
		>li {
			&:before {
				content: "\0025cf  ";
			}
		}
	}
	.lst-kix_list_1-4 {
		>li {
			&:before {
				content: "o  ";
			}
		}
	}
	ol.lst-kix_list_3-5.start {
		counter-reset: lst-ctn-kix_list_3-5 0;
	}
	.lst-kix_list_1-7 {
		>li {
			&:before {
				content: "o  ";
			}
		}
	}
	ol.lst-kix_list_2-7.start {
		counter-reset: lst-ctn-kix_list_2-7 0;
	}
	.lst-kix_list_1-5 {
		>li {
			&:before {
				content: "\0025aa  ";
			}
		}
	}
	.lst-kix_list_1-6 {
		>li {
			&:before {
				content: "\0025cf  ";
			}
		}
	}
	li.li-bullet-0 {
		&:before {
			margin-left: -18pt;
			white-space: nowrap;
			display: inline-block;
			min-width: 18pt;
		}
	}
	ol.lst-kix_list_2-1.start {
		counter-reset: lst-ctn-kix_list_2-1 0;
	}
	.lst-kix_list_1-8 {
		>li {
			&:before {
				content: "\0025aa  ";
			}
		}
	}
	ol {
		margin: 0;
		padding: 0;
	}
	table {
		td {
			padding: 0;
		}
		th {
			padding: 0;
		}
	}
	.c2 {
		margin-left: 30pt;
		padding-top: 0pt;
		text-indent: -30pt;
		padding-bottom: 10pt;
		line-height: 1.1500000000000001;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c4 {
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: "Verdana";
		font-style: normal;
	}
	.c10 {
		padding-top: 0pt;
		padding-bottom: 10pt;
		line-height: 1.0;
		orphans: 2;
		widows: 2;
		text-align: left;
		height: 11pt;
	}
	.c1 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: "Verdana";
		font-style: normal;
	}
	.c0 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: "Verdana";
		font-style: italic;
	}
	.c17 {
		padding-top: 0pt;
		padding-bottom: 10pt;
		line-height: 1.0;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c15 {
		padding-top: 0pt;
		padding-bottom: 10pt;
		line-height: 1.1500000000000001;
		orphans: 2;
		widows: 2;
		text-align: center;
	}
	.c20 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1.0;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c3 {
		padding-top: 0pt;
		padding-bottom: 10pt;
		line-height: 1.1500000000000001;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c21 {
		background-color: #ffffff;
		max-width: 523.3pt;
		padding: 36pt 36pt 36pt 36pt;
	}
	.c9 {
		font-size: 10pt;
		font-style: italic;
	}
	.c5 {
		margin-left: 60pt;
		text-indent: -30pt;
	}
	.c16 {
		margin-left: 36pt;
		padding-left: 0pt;
	}
	.c6 {
		color: #000000;
		font-size: 10pt;
	}
	.c8 {
		margin-left: 90pt;
		text-indent: -30pt;
	}
	.c18 {
		padding: 0;
		margin: 0;
	}
	.c13 {
		font-size: 10pt;
	}
	.c7 {
		margin-left: 30pt;
	}
	.c12 {
		color: #000000;
	}
	.c11 {
		font-weight: 700;
	}
	.c14 {
		height: 11pt;
	}
	.c19 {
		margin-left: 36pt;
	}
	.title {
		padding-top: 24pt;
		color: #000000;
		font-weight: 700;
		font-size: 36pt;
		padding-bottom: 6pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.subtitle {
		padding-top: 18pt;
		color: #666666;
		font-size: 24pt;
		padding-bottom: 4pt;
		font-family: "Georgia";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		font-style: italic;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	li {
		color: #000000;
		font-size: 11pt;
		font-family: "Verdana";
	}
	p {
		margin: 0;
		color: #000000;
		font-size: 11pt;
		font-family: "Verdana";
	}
	h1 {
		padding-top: 24pt;
		color: #000000;
		font-weight: 700;
		font-size: 24pt;
		padding-bottom: 6pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h2 {
		padding-top: 18pt;
		color: #000000;
		font-weight: 700;
		font-size: 18pt;
		padding-bottom: 4pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h3 {
		padding-top: 14pt;
		color: #000000;
		font-weight: 700;
		font-size: 14pt;
		padding-bottom: 4pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h4 {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 12pt;
		padding-bottom: 2pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h5 {
		padding-top: 11pt;
		color: #000000;
		font-weight: 700;
		font-size: 11pt;
		padding-bottom: 2pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h6 {
		padding-top: 10pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 2pt;
		font-family: "Verdana";
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.lst-kix_list_1-1 {
		>li {
			&:before {
				content: "o  ";
			}
		}
	}
}