.button--facebook-base {
  @extend .button--large;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
}

.button--facebook-connect {
  @extend .button--facebook-base;
  width: 210px;
  background: #4267B2;
}

.button--facebook-disconnect {
  @extend .button--facebook-base;
  background: $color--red1;
  width: 190px;
}

.button--instagram-connect {
  @extend .button--facebook-base;
  width: 210px;
  background: #000000;
}