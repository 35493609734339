.containerPayment {
	border: 1px solid #e8e8e8;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	max-width: 320px;
	background-color: #eeeeee;
	padding-bottom: 10px;
}

.cardTopBasic {
	text-align: center;
	width: 100%;
	--color: #34b4f5;
	--shadow: #2d9ed7;
	--border: #6da2c9;
}

.cardTopAuto {
	text-align: center;
	width: 100%;
	--color: #142960;
	--shadow: #122555;
	--border: #64607f;
}

.banner {
	padding: 20px 28px;
	background-color: var(--color);
	border-top: 2px solid var(--border);
	border-left: 2px solid var(--border);
	border-right: 2px solid var(--border);
	margin: 0px;
	color: #fff;
	width: 100%;

	/* box-shadow: 0px 10px 6px #000;
	z-index: 1; 2d9ed7 */
}

.banner > u {
	text-decoration: none;
}

.price-currency {
	display: flex;
	width: 100%;
	background-color: var(--color);
	padding-top: 08px;
	justify-content: center;

	-webkit-box-shadow: inset 0 5px 5px var(--shadow);
	-moz-box-shadow: inset 0 5px 5px var(--shadow);
	box-shadow: inset 0px 5px 8px var(--shadow);
}
.price {
	background-color: var(--color);
	margin: 0px;
	color: #fff;
	font-size: 30px;
	font-weight: bold;
}

.currency {
	background-color: var(--color);
	margin-top: 15px;
	color: #fff;
}

.monthlySub {
	background-color: var(--color);
	margin: 0px;
	color: #fff;
	width: 100%;
}

.discount {
	background-color: var(--color);
	margin: 0px;
	color: #fff;
}

.triangle {
	background-color: var(--color);
	max-width: 400px;
	width: auto;
	margin: 0;
	height: 50px;
	background: linear-gradient(to top right, transparent 50%, var(--color) 51%) 0
			0/50% 100% no-repeat,
		linear-gradient(to top left, transparent 50%, var(--color) 51%) 100% 0 /50% 100%
			no-repeat;
}
